@use '../abstract/mixins' as mixin;
@use "../abstract/responsive" as rwd;

$c-boulder: #7A7A7A;   

// Competitions section
.competitions {
  @include rwd.breakpoint(mobile) {
    /* margin-bottom: -30px; */
  }

  &__list {
    > [class*=col-] {
      padding-left: 10px;
      padding-right: 10px;

      @include rwd.breakpoint(mobile) {
        &:nth-of-type(odd) {
            padding-right: 7px !important;
        }
    
        &:nth-of-type(even) {
            padding-left: 7px !important;
        }
      }
    }
  }

  .boxWithImg {
    margin-bottom: 30px;

    &.endedContest {
      @include rwd.breakpoint(mobile) {
        display: none;
      }

      .textLabel {
        color: $c-boulder;
      }
    }

    .boxTitle {
      @include mixin.font(18px, 24px);
      margin-top: 3px;
      @include mixin.showLines(3);

      @include rwd.breakpoint(mobile) {
        @include mixin.font(14px, 20px);
        margin-top: 2px;
      }
    }

    img {
      @include rwd.breakpoint(tabletmobile) {
        width: 100%;
      }
    }
  }
}